<template>
	<Layout>
		<template>
			<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
				<form @submit.prevent="handleSubmit(updateProfile)">
					<article class="profile__column panel">
						<div class="panel__header">
							<span class="is-flex is-flex-direction-column is-justify-content-center">
								<h3 class="profile__name pt-0 is-semibold is-size-4">{{ $t('menu.profile') }}</h3>
								<p class="is-size-6">{{ $t('profile.personal') }}</p>
							</span>
							<b-button v-if="isDesktop" id="button-profile-personal" tabindex="6" native-type="submit" type="is-primary save" :loading="loading" rounded>{{ $t('buttons.save') }}</b-button>
						</div>
						<div class="panel__body">
							<h3 class="profile__section mb-5 has-text-primary is-semibold is-size-5">{{ $t('labels.personal_data') }}</h3>
							<InputWithValidation class="profile__field" tab="1" rules="required|min:2" :label="$t('fields.first_name')" name="firstname" size="is-medium" v-model="user.first_name" translate="no" />

							<InputWithValidation class="profile__field" tab="2" rules="required|min:2" :label="$t('fields.last_name')" name="lastname" size="is-medium" v-model="user.last_name" />

							<InputWithValidation class="profile__field" tab="3" rules="required|email" field="email" :label="$t('fields.email')" name="email" size="is-medium" v-model="user.email" />

							<span class="is-block is-relative profile__field">
								<b-field :label="$t('fields.birthdate')">
									<b-datepicker id="field-birthdate" position="is-top-right" tabindex="4" v-model="user.birth_date" :max-date="new Date()" :locale="$i18n.locale"></b-datepicker>
								</b-field>
							</span>

							<div class="is-flex is-justify-content-flex-end mb-3">
								<b-button v-if="!isDesktop" id="button-profile-personal" tabindex="6" native-type="submit" type="is-primary save" :loading="loading" rounded>{{ $t('buttons.save') }}</b-button>
							</div>
						</div>
					</article>
				</form>
			</ValidationObserver>
		</template>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Profile'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import { mapState, mapActions } from 'vuex'
import { toast } from '@/mixins/toast'
import { responsive } from '@/mixins/responsive'
import '@/mixins/date'

export default {
	name: 'Personal',
	mixins: [responsive],
	components: {
		Layout,
		InputWithValidation,
		ValidationObserver
	},
	data() {
		return {
			loading: false
		}
	},
	created() {
		this.dateParser()
	},
	methods: {
		...mapActions('user', [
			'getUserData'
		]),
		async updateProfile() {
			try {
				this.loading = true
				if (this.user.site) {
					const url = this.user.site.replace('http://', '').replace('https://', '')
					this.user = { ...this.user, site: `http://${url}` }
				}
				const response = await Api.put(`users/personal`, this.user)
				const { status } = response
				if (status === 200) {
					this.getUserData(true)
					toast('is-success', this.$t('alerts.update.success', [this.$tc('menu.roles')]))
				}
			} catch (e) {
				const { status } = e
				if (status === 500) {
					toast('is-danger', this.$t('alerts.email_duplicated'))
				}
			} finally {
				this.loading = false
			}
		},
		dateParser() {
			if (this.user) {
				if (this.user.birth_date) {
					let date = this.toUTC(this.user.birth_date).format()
					this.user.birth_date = new Date(Date.parse(date))
				}
			}

			return null
		}
	},
	watch: {
		user() {
			this.dateParser()
		}
	},
	computed: {
		...mapState('user', [
			'user'
		])
	}
}
</script>
